@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800|Roboto+Slab:300,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arsenal:wght@400;700&display=swap');

html, body {
    width: 100%;
    overflow-x: hidden;
}

body {
    font-family: 'Montserrat', sans-serif;
    line-height: normal;
    color: #252525;
    background-color: #ffffff !important;
    font-size: 16px;
}
@font-face {
    font-family: 'FaunaOne';
    src: url('../assets/fonts/FaunaOne.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/FaunaOne.woff') format('woff'),
        url('../assets/fonts/FaunaOne.ttf')  format('truetype'),
        url('../assets/fonts/FaunaOne.svg#FaunaOne') format('svg');
    font-weight: normal;
    font-style: normal;
}


a.active,
a:focus,
button:focus,
button.active {
    outline: none;
}

a {
    text-decoration: none;
}

a:focus,
a:hover,
a:active {
    outline: 0;
    box-shadow: none;
}

a:focus,
a:hover {
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
}

h3 p {
    color: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit !important;
    text-align: inherit !important;
    margin-top: inherit !important;
}

a {
    text-decoration: none;
    transition: all .5s ease-in-out;
}

a:hover {
    text-decoration: none;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul li {
    list-style: none;
}

p {
    margin: 0;
}

.fa-2x {
    font-size: 1.6em;
}

.text-block h1 {
    color: #252525;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
}
.text-block h2 {
    color: #3c2c1b;
    font-size: 40px;
    line-height: 46px;
    font-family: 'Arsenal', sans-serif;
    margin: 15px 0 25px;
    position: relative;
}
.text-block h3 {
    color: #252525 !important;
    line-height: 24px !important;
    font-size: 32px;
    padding-bottom: 8px !important;
    font-family: 'Arsenal', sans-serif;
}
.text-block p {
    color: #555;
    line-height: 32px;
    font-size: 16px;
    margin-bottom: 20px;
    font-family: "Montserrat",sans-serif;
}

.text-block ul li {
    color: #555;
    line-height: 32px;
    font-size: 16px;
    margin-bottom: 20px;
}

.text-block ul li::before {
    content: "•";
    position: absolute;
    left: -0.7em;
    font-size: 1.0em;
}

.text-block .media div:nth-child(1){
    width: 100%;
    height: auto;
}

.text-block .image {
    text-align: center;
}

.text-block .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}

.text-block .image-style-align-left {
    float: left;
}

.text-block .image-style-align-right {
    float: right;
}
.text-block .image .image_resized {
    display: block;
}

input:focus,
select:focus,
textarea:focus {
    outline: none;
}

img {
    border-style: none;
    display: inline-block;
    max-width: 100%;
    height: auto;
}
.wrapper{
    overflow: hidden;
}

/*--------------------------------------
BUTTON CSS
-------------------------------------*/

.btn {
    height: 55px;
    min-width: 263px;
    padding: 0 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 27.5px;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.btn:focus {
    box-shadow: none;
}

.cta-primary {
    background-color: #fff;
}


/*--------------------------------------
HEADER START
------------------------------------*/

header {
    background-color: #3c2c1b;
}

header .navbar {
    align-items: center;
    max-width: 1170px !important;
    margin: auto;
    padding: 40px 15px 80px 15px
}

header .navbar-light .navbar-nav .nav-link:focus {
    color: white
}

header .navbar-light .navbar-nav .nav-link:hover {
    color: white
}

header .navbar .navbar-brand img {
    height: 70px;
}

header .navbar-collapse {
    flex-direction: column;
}

header .contact-nav {
    display: flex;
    margin-left: auto;
    margin-bottom: 0 !important;

}

header .header-message {
    background-color: #7e4013;
    color: #ffffff;
    padding: 5px;
}

.upper-container {
    padding: 15px 42px 15px;
    background-color: #655441;
    margin: -10px 0 0 0;
}

.customer-cart-icon {
    color: #ffffff;
    background-color: red;
    text-align: center;
    border-radius: 8px;
    font-size: 10px;
    position: absolute;
    top: 15px;
    margin-left: 8px;
    padding: 0px 8px;
}

#desktop-navbar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    width: 100%;
    padding: 20px 15px 20px 0;
}

.top-of-nav {
    text-align: right;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fafafa;
    letter-spacing: 2.5px;
}

#navbar-background {
    position: absolute;
    bottom: 0;
    height: 65px;
    width: 100%;
    background-color: #292929;
}

header .contact-nav {
    margin-bottom: 11px;
}

header .contact-nav li a {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fafafa;
    letter-spacing: 2.5px;
}

header .navbar-nav .nav-item {
    z-index: 9;
}

header .navbar-nav .nav-item .nav-link {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 2px;
    padding: 0 15px;
}

header .navbar-nav .nav-item:last-child .nav-link {
    padding-right: 0;
}

.dropdown-item:hover {
    background-color: white;
    width: 80%;
}

.dropdown-item:active {
    background-color: white;
}

#menu-dropdown {
    position: absolute;
    margin-top: -20px;
    border-top: 40px solid rgba(0, 0, 0, 0);
    border-left: 0px;
    border-right: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 1;
}

.dropdown-toggle {
    position: relative;
    z-index: 2;
}

.dropdown-toggle:hover > .dropdown-menu {
    display: block;
}


.nav-link .dropdown-menu {
    opacity: 0;
    visibility: hidden;
    display: block;
    transition: all 0.3s;
    padding: 10px 0;
}

.dropdown-menu.show {
    color: #3c2c1b;
    background-color: white;
    border: none;
    visibility: visible;
    opacity: 1;
    transition: all 0.3s
}

.menu-dropdown-center {
    right: auto;
    /* left: 50%; */
    /* transform: translate(-50%, 0); */
}

#menu-dropdown a{
    color: #3c2c1b;
    font-size: 11px;
    padding: 5px 10px;
}

#menu-dropdown:after, #menu-dropdown:before {
    top: -16px;
    left: 110px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    transform: translateX(50%);
    position: absolute;
    pointer-events: none;
}

#menu-dropdown:after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 30px;
    margin-left: -30px;
}
#menu-dropdown:before {
    border-color: rgba(194,225,245,0) rgba(194,225,245,0) #fff;
    border-width: 10px;
    margin-left: -100px;
}

#search-dropdown {
    margin-top: 15px;
    border-radius: 0;
    margin-right: 3px;
}

#search-dropdown:after, #search-dropdown:before {
    top: -16px;
	left: 96.5%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

#search-dropdown:after {
	border-color: rgba(255, 255, 255, 0);
	border-width: 30px;
	margin-left: -30px;
}
#search-dropdown:before {
	border-color: rgba(194, 225, 245, 0);
	border-bottom-color: #ffffff;
	border-width: 10px;
	margin-left: -10px;
}

#search-dropdown-input {
    float: left;
    width: 450px;
    height: 40px;
    border: none;
}

.dropdown-toggle.noCaret:after {
    display: none;
    cursor: pointer
}

#searchclear {
    position: absolute;
    right: 20px;
    top: 0px;
    bottom: 5px;
    height: 14px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    color: rgb(145, 136, 136);
}

::-webkit-input-placeholder {
    font-style: italic;
 }
 :-moz-placeholder {
    font-style: italic;
 }
 ::-moz-placeholder {
    font-style: italic;
 }
 :-ms-input-placeholder {
    font-style: italic;
 }

/*-------------------------------
HERO SLIDER
----------------------------------*/

.header-home {
    min-height: 655px;
    max-height: 655px;
}

.hero-text {
    padding: 177px 0 260px;
}

.hero-text h1 {
    color: #fff;
    font-family: 'Montserrat', serif;
    font-weight: 800;
    font-size: 72px;
    margin-bottom: 35px;
    text-align: center;
    text-transform: uppercase;
}

.hero-button {
    max-width: 263px;
    margin: auto;
}

.hero-button a {
    background-color: #fca311;
}

.hero-button a:hover {
    opacity: 0.7;
}


.hero-text h1 sup {
    font-size: 26px;
    top: -1.4em;
    left: -0.5em;
}
.carousel-btn{
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
}
.carousel-control-prev-icon,
.carousel-control-next-icon{
    background: none;
}
.carousel-control-next, .carousel-control-prev{
    position: static;
    opacity: 1;
    width: auto;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    width: auto;
    height: auto;
    opacity: .65;
}
.carousel-btn-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.carousel-indicators {
    overflow: hidden;
}

/*--------------------------------------
HARDWOOD OPTION CSS
-------------------------------------*/
.hardwood-option-sec{
    padding: 136px 0;
}
.title{
    position: relative;
    padding-bottom: 32px;
}
.title span{
    color: #252525;
    font-size: 12px;
    display: block;
    font-weight: 400;
    letter-spacing: 4px;
    margin-bottom: 7px;
    letter-spacing: 1px;
}
.title h2{
    color: #292929;
    font-size: 51px;
    font-weight: 800;
    font-family: 'Montserrat';
    text-align: center;
    text-transform: uppercase;
    line-height: 54px;
}

.title p {
    color: #d40023;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    text-align: center;
    margin-top: 10px;
}

.title .featured-text {
    color: #555;
    line-height: 32px;
    font-size: 16px;
    font-family: "Montserrat",sans-serif;
    text-align: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: unset;
    font-weight: 500;
    max-width: 1140px;
    width: 80%;
}

.title .featured-text, .title .featured-text p {
    color: #555;
    line-height: 32px;
    font-size: 16px;
    font-family: "Montserrat",sans-serif;
    text-align: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: unset;
    font-weight: 500;
    max-width: 1140px;
}

.product-category-featured, .product-category-featured:hover, .product-category-featured:focus {
    color: #292929;
    font-size: 18px;
    font-weight: 600;
    display: block;
    text-transform: uppercase;
}

.top-products-container {
    padding: 35px 0 35px 0;
}

.top-selling-products-row {
    padding-top: 60px;
    justify-content: center;
    max-width: 1640px;
    margin-left: auto;
    margin-right: auto;
}

.top-selling-option-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: center;
    padding: 20px
}

.top-selling-option-box .top-selling-product-title, .top-selling-product-price {
    text-align: left;
}

.top-selling-block {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
}

.top-selling-block:first-child {
    border-right: 1px solid #e5e5e5;
}

.top-selling-block:nth-child(n+5) {
    margin-top: 60px;
}

.top-selling-block:nth-child(1) {
    border-right: 1px solid #e5e5e5;
}

.top-selling-block:nth-child(4) {
    border-right: none;
}

.top-selling-block:nth-child(8) {
    border-right: none;
}

.home-page-top-sellers .top-selling-option-box {
    padding-right: 20px !important;
    margin-top: -40px;
    padding-left: 20px !important;
    padding-bottom: 40px !important;
}

.top-selling-option-box .main-top-seller-image {
    width: 100% !important;
    max-height: 166.694px;
    min-height: 166.694px;
    object-fit: cover;
}

.top-selling-option-box .secondary-top-seller-image {
    width: 100% !important;
    max-height: 96.77px;
    min-height: 96.77px;
}

.top-selling-option-box .image-holder img {
    border-radius: 4px !important;
    object-fit: cover;
}

.top-selling-product-price {
    margin-top: 15px;
    color: #292929;
    font-size: 25px;
    font-weight: 600;
    display: block;
}

.top-selling-product-price sup {
    font-size: 15px;
    top: -7px;
}

.top-selling-product-title, .top-selling-product-title a {
    flex: 1 1;
    color: #292929;
    font-size: 14px;
    font-weight: 600;
    display: block;
}

.top-selling-add-cart-button {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #d40023;
    border-color: #d40023;
    height: 35px;
    min-width: 230px;
    font-size: 14px;
    font-weight: 500;
}

.top-selling-add-cart-button:hover, .top-selling-add-cart-button:focus {
    background-color: #d40023;
    border-color: #d40023;
    opacity: 0.7;
}

.top-selling-products-secondary-row {
    padding-top: 30px;
    justify-content: center;
    margin-top: 20px;
}

.top-selling-products-secondary-row .top-selling-block {
    border: none;
    max-width: 300px;
    position: relative;
}

.top-selling-products-secondary-row .top-selling-block:nth-child(n+1):after {
    content: '';
    height: 40%;
    width: 1px;
    position: absolute;
    right: 0;
    top: 15%;
    background-color: #e5e5e5;
}

.top-selling-products-secondary-row .top-selling-block:nth-child(6):after {
    width: 0px;
}

.top-selling-products-secondary-row .top-selling-block:nth-child(n+5) {
    margin-top: 0px;
}

.top-selling-products-secondary-row .top-selling-block:nth-child(n+5) {
    margin-top: 0px;
}

.top-selling-products-secondary-row .top-selling-product-price {
    font-size: 16px;
}

.top-selling-products-secondary-row .top-selling-product-title {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

}

.brand-button-container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.brands-button {
    margin: 40px auto;
    background-color: #fca311;
    border-color: #fca311;
    width: 100% !important;
    min-width: 100px !important;
    max-width: 263px !important;
}

.brands-button:hover {
    background-color: #fca311;
    border-color: #fca311;
    opacity: 0.7;
}

.brand-image-container {
    margin-left: auto;
    margin-right: auto;
}

.brand-button {
    background-color: #eef2fe;
    border-radius: 4px
}

.brand-button img {
    padding: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.brand-images {
    margin-left: auto;
    margin-right: auto;
}

.hardwood-option-inner{
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    justify-content: space-between;
    gap: 30px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}
.hardwood-option-inner .hardwood-block {
    flex-basis: 30%;
    flex-grow: 1;
}

.hardwood-option-inner .hardwood-block:last-of-type {
    margin-right: auto;
}

.hardwood-option-box{
    border: 1px solid #cacaca;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 700px;
    text-align: center;
    padding: 20px;
    margin: 0;
}
.hardwood-option-box img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 600px;
    width: 643px;
    margin-bottom: 20px;
    object-fit: cover;
    object-position: 50% 0%;
}

.hardwood-option-box .text-box{
    padding-top: 25px;
}
.hardwood-option-box .text-box h3{
    color: #850037;
    line-height: 24px;
    font-size: 16px;
    height: 56px;
    padding-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: .06em;
}
.hardwood-option-box .text-box p{
    color: #252525;
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 38px;
    height: 56px;
    overflow: hidden;
}
.hardwood-option-box .text-box a{
    color: #252525;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #cacaca;
    max-width: 260px;
    width: 100%;
    height: 56px;
    border-radius: 50px;
    background-color: #fafafa;
    margin-bottom: -49px;
}
.hardwood-option-box .text-box a:hover{
    background-color: #850037;
    border-color: #850037;
    color: #fff;
}
.hardwood-option-detail{
    border: 1px solid #cacaca;
    padding: 20px;
    margin-top: 50px;
}
.hardwood-option-detail .image-holder img{
    width: 100%;
}
.hardwood-option-detail .text-box{
    padding-top: 23px;
}
.hardwood-option-detail .text-box h3{
    color: #850037;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 7px;
    text-transform: uppercase;
}
.hardwood-option-detail .text-box p{
    color: #252525;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 45px;
}
.hardwood-option-detail .text-box a{
    color: #252525;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #cacaca;
    max-width: 260px;
    width: 100%;
    height: 56px;
    border-radius: 50px;
    background-color: #fff;
    margin-bottom: -49px;
}
.hardwood-option-detail .text-box a:hover{
    background-color: #850037;
    border-color: #850037;
    color: #fff;
}
/*--------------------------------------
HARDWOOD LIST CSS
-------------------------------------*/
.hardwood-list{
    padding: 40px 0 110px 0;
    background-color: #ffffff;
}
.hardwood-list-inner{
    padding-bottom: 100px;
    background-color: #ffffff;
}
.hardwood-list-inner .image-holder img{
    width: 100%;
    height: 600px;
    object-fit: cover;
}
.hardwood-list-inner .text-box{
    display: flex;
    align-items: center;
}
.hardwood-list-inner .text-box ul {
    list-style: disc;
    text-align: left;
}
.hardwood-list-inner .text-box ul li {
    width: 274px;
}
.hardwood-list-inner .text-box ul li::before {
    position: relative;
}
.hardwood-list-inner .text-box ol {
    list-style: decimal;
    text-align: left;
}
.hardwood-list-inner .text-box ol li {
    width: 274px;
    margin-left: 29px
}
.hardwood-list-inner .text-box ol li::before {
    position: relative;
}
.hardwood-list-inner.hardwood-list-inner-right .text-box ul {
    list-style: disc;
    text-align: left;
}
.hardwood-list-inner.hardwood-list-inner-right .text-box ul li {
    width: 274px;
    margin-left: auto;
}
.hardwood-list-inner.hardwood-list-inner-right .text-box ul li::before {
    position: relative;
}
.hardwood-list-inner.hardwood-list-inner-right .text-box ol {
    list-style: decimal;
    text-align: left;
}
.hardwood-list-inner.hardwood-list-inner-right .text-box ol li {
    width: 270px;
    margin-left: auto;
}
.hardwood-list-inner.hardwood-list-inner-right .text-box ol li::before {
    position: relative;
}
.text-block ol li {
    color: #555;
    line-height: 32px;
    font-size: 16px;
    margin-bottom: 0px;
    position: relative;
}
.hardwood-list-inner .text-inner {
    background-color: #ffffff;
    width: 100%;
    min-height: 446px;
    padding: 60px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 63px;
}
.hardwood-list-inner .text-inner h3 {
    color: #3c2c1b;
    font-size: 52px;
    line-height: 50px;
    font-family: 'Arsenal', sans-serif;
    display: inline-block;
    padding-bottom: 30px;
    position: relative;
}
.hardwood-list-inner .text-inner h3:after{
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    background-color: #cacaca;
    height: 1px;
    width: 255px;
}
.hardwood-list-inner .text-inner span{
    font-weight: 500;
    display: block;
    color: #850037;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 27px 0 9px;
    font-size: 16px;
}
.hardwood-list-inner-right .text-box{
    display: flex;
    align-items: center;
    margin-top: 48px;
}
.hardwood-list-inner-right .text-inner{
    text-align: right;
    padding-left: 0;
    padding-right: 63px;
    background-color: #ffffff;
    height: 446px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.hardwood-list-inner-right .text-inner h3:after{
    left: auto;
    right: 0;
}
/*--------------------------------------
DISTRIBUTION CSS
-------------------------------------*/
.distributor-sec{
    background: url(../assets/images/map-bg.png) no-repeat;
    background-size: cover;
    text-align: center;
    padding:  0 0 150px 0;
}
.dstributor-inner {
    background: url(../assets/images/distributors.svg) no-repeat;
    background-size: cover;
    padding: 140px 0;
    margin-top: -75px;
}
.dstributor-inner span{
    color: #eeeeee;
    text-align: center;
    text-transform: uppercase;
    max-width: 350px;
    width: 100%;
    font-size: 10px;
    margin: 0 auto;
    display: block;
    border-bottom: 1px solid #7e8a86;
    padding-bottom: 37px;
    letter-spacing: 3px;
    font-size: 10px;
}
.dstributor-inner h3{
    color: #eeeeee;
    font-size: 58px;
    padding: 47px 0 13px 0;
    font-family: 'Roboto Slab', serif;
    font-weight: 300;
}
.dstributor-inner p{
    color: #eeeeee;
    line-height: 28px;
    font-size: 16px;
    max-width: 790px;
    width: 100%;
    text-align: left;
    margin: 0 auto 40px;
}
.dstributor-inner form{
    display: flex;
    align-items: center;
    max-width: 780px;
    width: 100%;
    margin: 0 auto;
}
.dstributor-inner .form-control{
    border-radius: 0;
    border-left: 0;
    border-bottom: 1px solid #bababa;
    border-top: 0;
    color: #fafafa;
    font-size: 16px;
    font-style: italic;
    border-right: 0;
    padding-left: 30px;
    padding-bottom: 0;
    background-color: transparent;
    width: calc(100% - 261px);
    margin-right: 30px;
    height: 53px;
}
.dstributor-inner .form-control::placeholder{
    color: #fafafa;
}
.dstributor-inner form a{
    color: #001f15;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: #eeeeee;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 261px;
    height: 54px;
    width: 100%;
}
.dstributor-inner form a:hover{
    background-color: #850037;
    color: #fff;
}

#canada-dist {
    position: relative;
    top: 30px;
    color: #bec4c2;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .05em;
}

#canada-dist h6 {
    color: white;
    margin-bottom: 15px;
}

#canada-dist ul {
    margin-bottom: 10px;
}

#canada-dist a {
    color: #bec4c2;
    border-bottom: 1px solid #bec4c2;
}

#canada-dist-home {
    margin: auto;
    max-width: 780px;;
    top: 20px;
    right: 165px;
    color: #bec4c2;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .05em;

}

#canada-dist-home h6 {
    margin-top: 30px;;
    color: white;
    text-align: left;
    margin-bottom: 15px;
}

#canada-dist-home p {
    color: #bec4c2;
    line-height: unset;
    margin-bottom: 0px;
}

#canada-dist-home ul {
    text-align: left;
    margin-bottom: 10px;
}

#canada-dist-home a {
    float: left;
    color: #bec4c2;
    border-bottom: 1px solid #bec4c2;
}
/*--------------------------------------
FAMILY OWNED CSS
-------------------------------------*/
.family-owned-sec{
    text-align: center;
    padding: 145px 0;
}
.family-owned-inner {
    background-color: rgba(60, 44, 27, 0.8);
    padding: 60px;
}

.family-owned-inner span{
    color: #eeeeee;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    max-width: 420px;
    width: 100%;
    font-size: 10px;
    margin: 0 auto;
    display: block;
    border-bottom: 1px solid #555555;
    padding-bottom: 15px;
}
.family-owned-inner h3{
    color: #eee;
    font-size: 72px;
    padding: 41px 0 29px;
    font-family: 'Arsenal';
    line-height: 75px;
}
.family-owned-inner ul li {
    list-style: disc;
    list-style-position: inside;
}
.family-owned-inner ol li {
    list-style: decimal;
    list-style-position: inside;
}
.family-owned-inner p{
    color: #eeeeee;
    line-height: 28px;
    font-size: 16px;
    max-width: 780px;
    margin: 0 auto;
}

.family-owned-inner a {
    margin-top: 30px;
    background-color: #209522;
    color: #ffffff
}

.family-owned-inner a:hover {
    background-color: #209522;
    color: #ffffff;
    opacity: 0.7;
}
/*--------------------------------------
FAMILY OWNED CSS
-------------------------------------*/
.slideshow-sec{
    padding: 20px 0;
}
.slideshow-sec .owl-theme .owl-nav [class*=owl-]{
    margin: 0 15px;
}
.slideshow-sec .owl-theme .owl-nav [class*=owl-]:hover{
    background-color: transparent;
}
.slideshow-sec .owl-theme .owl-nav {
    margin-top: 0;
    position: absolute;
    width: 100%;
    bottom: 24px;
}
/*--------------------------------------
FOOTER CSS
-------------------------------------*/
.footer-sec{
    background-color:#3c2c1b;
    padding: 80px 80px 25px;
    letter-spacing: .10em;
}

.footer-container {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
}

.footer-right {
    margin-left: auto;
    margin-top: 39px;
    white-space: nowrap;
}

.social-icons ul {
    display: flex;
    justify-content: end;
}

.social-icons li{
    padding: 5px;
}

.social-icons a {
    color: #bec4c2;
}

.footer-site-logo img {
    display: block;
    max-width: 214px !important;
}

.footer-site-logo p {
    display: block;
    margin-top: 25px;
    color: #bec4c2;
    text-align: start;
    letter-spacing: .05em;
    font-weight: 400;
    line-height: 24px;
}

.footer-inenr .text-box ul{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}
.footer-inenr .text-box ul li a{
    color: #bec4c2;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 8px;
    padding: 0 18px;
    position: relative;
}
.footer-inenr .text-box ul li a:hover{
    color: #850037;
}
.footer-inenr .text-box ul li:first-child a:after{
    display: none;
}
.footer-inenr .text-box ul li a:after{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    background-color: #bec4c2;
    width: 4px;
    height: 4px;
    border-radius: 50%;
}
.footer-inenr .text-box p{
    color: #bec4c2;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 12px;
    margin-bottom: 5px;
}
.footer-inenr .text-box p span{
    display: inline-block;
    padding-left: 17px;
}
.copyright {
    display: flex;
    margin-top: 35px;
}

.copyright p, .copyright span {
    font-size: 10px;
    color: #9d958e;
    font-weight: 600;
    letter-spacing: .3em;
    text-transform: uppercase;
}

.copyright p br{display: none;
}
.copyright p span{
    display: inline-block;
}
.copyright a{
    color: #845818;
    text-decoration: none;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: .3em;
    text-transform: uppercase;
    margin-left: auto;
}
.footer-logo{
    position: relative;
    margin: 75px 0 0 0;
}
.footer-logo:after{
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    width: 100%;
    height: 1px;
    background-color: #00936d;
}
.footer-logo ul{
    display: flex;
    align-items: center;
    background-color: #3c2c1b;
    justify-content: center;
    position: relative;
    z-index: 1;
    max-width: 510px;
    width: 100%;
    margin: 0 auto;
}
.footer-logo ul li{
    margin: 0 30px;
}

.quick-links {
    display: flex;
    justify-content: end;
    margin-top: 25px;
    text-transform: uppercase;
}

.quick-links p {
    color: #fff;
    margin-bottom: 25px;
    text-align: right;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: .05em;
    font-weight: 400;
}

.quick-links ul li, .quick-links ul li a {
    text-align: right;
    margin-bottom: 15px;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;
    color: #FFF;
    opacity: 0.9;
}

.quick-links div:not(:nth-child(3)) {
    margin-right: 70px;
}


.copyrightContainer {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}

.doc-4-badge {
    display: flex;
}
/*--------------------------------------
SEARCH RESULT CSS
-------------------------------------*/
.search-result-sec{
    padding: 60px 0 125px;
    background-color: #ffffff;
}
.search-result-box {
    margin-top: 45px;
}
.search-result-detail {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 0px;
}
.search-result-detail .title {
    padding-left: 15px;
    padding-bottom: 10px;
}
.search-result-inner .title span{
    margin-bottom: 0;
    margin-top: 8px;
    font-size: 14px;
}
#search-filter {
    list-style-type: unset;
}
.filter-options{
    margin-left: auto;
    display: none;
    width: 100%;
}
.filter-options a{
    color: #f64750;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.filter-options a img {
    max-width: 15px;
    width: 100%;
    margin-left: 5px;
    transform: rotate(-85deg);
}
.search-result-detail ul li{
    padding-left: 40px;
    list-style: disc;
    list-style-position: inside;
}
.search-result-detail ol li{
    padding-left: 40px;
    list-style: decimal;
    list-style-position: inside;
}
.search-result-detail ul li a{
    text-transform: uppercase;
    font-size: 10px;
    color: #555555;
    letter-spacing: 3px;
}
.search-result-detail ul li a span{
    color: #f64750;
}
.search-result-detail ul li a img{
    max-width: 10px;
    width: 100%;
    margin-left: 1px;
}
.search-result-detail ul li .dropdown-menu{
    border: 1px solid #cacaca;
    border-radius: 0;
    background-color: #fff;
    min-width: 170px;
    padding: 15px;
}
.search-result-detail ul li .dropdown-menu .dropdown-item{
    color: #555555;
    font-size: 14px;
    font-weight: 400;
    display: block;
    border-bottom: 1px solid #f0f0f0;
    padding: 11px;
}
.search-result-detail ul li .dropdown-menu .dropdown-item:hover{
    background-color: transparent;
    color: #f64750;
}
.search-result-box .text-box{
    border: 1px solid #cacaca;
    padding: 46px 40px 55px;
    margin-bottom: 30px;
}
.search-result-box .text-box p {
    color: #252525;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 4px;
    max-width: 230px;
    margin-bottom: 5px;
    line-height: 18px;
}
.search-result-box .text-box h3{
    color: #536c66;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 28px;
}
/*--------------------------------------
COLLECTION DETAIL CSS
-------------------------------------*/
.carousel-thumbs {
    width: 100%;
    min-width: 50px;
    max-width: 50px;
    height: 100%;
    min-height: 50px;
    max-height: 50px;
}
.collection-top-logo {
    max-width: 300px
}
.collection-detail-sec{
    background-color: #fafafa;
    padding: 70px 0;
}
.collection-breadcrumb{
    margin-bottom: 30px;
}
.collection-breadcrumb .breadcrumb{
    padding: 0;
    background-color: transparent;
    margin: 8px 0 0 0;
}

.collection-breadcrumb .breadcrumb-item, .collection-breadcrumb .breadcrumb-item a {
    color: #252525;
    font-size: 14px;
    margin: -10px 0 0 -2px;
    vertical-align: middle;
}

.breadcrumb-item+.breadcrumb-item::before {
    font-family: "Material Icons";
    color: #252525;
    content: "\e5cc";
}
.payment-widget-product {
    padding: 40px 0 10px 0;
}
.product-qty {
    width: 80px;
    text-align: center;
    font-weight: 600;
    border-radius: 8px;
    border: 2px solid lightgray;
    background-color: #f8f8f8;
    margin-right: 20px;
}
.productTotalCost {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto
}
.product-add-cart-button {
    background-color: #d40023;
    border-color: #d40023;
}
.product-add-cart-button:hover, .product-add-cart-button:focus, .product-add-cart-button:disabled {
    background-color: #d40023;
    border-color: #d40023;
    opacity: 0.7;
}
.collection-detail-sec .text-plank{
    padding: 75px 0px 0px 32px;
}
.collection-detail-sec .text-plank ul li{
    /*padding-left: 40px;
    list-style: disc;*/
    list-style-position: inside;
}
.collection-detail-sec .text-plank ol li{
    padding-left: 40px;
    list-style: decimal;
    list-style-position: inside;
}
.collection-detail-sec .text-plank span{
    color: #252525;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.collection-detail-sec .text-plank h3{
    color: #3c2c1b;
    font-size: 40px;
    line-height: 46px;
    font-family: 'Arsenal', sans-serif;
    margin: 15px 0 25px;
    position: relative;
}

.collection-detail-sec .text-plank .product-costs{
    padding-bottom: 32px;
    font-weight: 600;
}

.collection-detail-sec .text-plank .product-costs sup{
    font-size: 20px;
    top: -10px
}
/*.collection-detail-sec .text-plank h3:after{
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    background-color: #cacaca;
    height: 1px;
    width: 255px;
}*/
.collection-detail-sec .text-plank h4{
    color: #850037;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 3px;
    margin-bottom: 7px;
}
.collection-detail-slide .carousel-inner{
    background-color: #fff;
    border: 1px solid #cacaca;
    padding: 20px;
}
.collection-detail-slide .carousel-indicators{
    position: static;
    margin-right: 0;
    margin-left: 0;
    padding: 0 10px;
    margin-top: 20px;
}
.collection-detail-slide .carousel-thumbs{
  object-fit: cover;
}
.carousel-indicators li{
    width: auto;
    padding: 0 10px;
    margin: 0;
    height: auto;
}
.collection-gallery-arrows {
    margin-top: -50px;
    margin-bottom: 50px;
}
.carousel-control-prev-icon{
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
}
.carousel-control-next-icon {
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
}
.collection-grades{
    border-top: 1px solid #cacaca;
    border-bottom: 1px solid #cacaca;
    margin: 90px 0 0 0;
    padding-top: 72px;
    padding-bottom: 67px;
}
.collection-grades ol li {
    padding-left: 40px;
    list-style: decimal;
    list-style-position: inside;
}
.heading h3{
    color: #3c2c1b;
    font-size: 40px;
    line-height: 46px;
    font-family: 'Arsenal', sans-serif;
    margin: 15px 0 25px;
    position: relative;
}
.collection-grades-inner{
    padding: 40px 0 0 0;
}
.collection-grades-box .image-holder{
    background-color: #ffffff;
    border: 1px solid #cacaca;
    padding: 20px;
}
.collection-grades-box .image-holder img{
    display: block;
    margin: auto;
    width: 100%;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
    object-fit: cover;
}
.collection-grades-box{
    margin: 30px 0;
}
.collection-grades-box h3{
    text-align: center;
    color: #252525;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    padding: 20px 0 0;
}
.collection-packaging{
    padding: 65px 0 0 0;
}
.collection-packaging .heading ul li {
    padding-left: 40px;
    list-style: disc;
    list-style-position: inside;
}
.collection-packaging .heading ol li {
    padding-left: 40px;
    list-style: decimal;
    list-style-position: inside;
}
.collection-packaging-inner{
    padding: 40px 0 0 0;
}
.collection-packaging-inner{
    padding: 40px 0 0 0;
}
.collection-packaging-left h3{
    color: #252525;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 12px;
}
.collection-packaging-left .text-box{
    padding-bottom: 43px;
}
.collection-packaging-left .text-box p{
    color: #555555;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
}
.collection-packaging-left .text-box ul{
    padding-left: 33px;
}
.collection-packaging-left .text-box ul li{
    color: #555555;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 13px;
}
.collection-packaging-left .text-box ul li:last-child{
    margin-bottom: 0;
}
.collection-packaging-right .text-box{
    margin-bottom: 66px;
}
.collection-packaging-right .text-box h3{
    color: #252525;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 12px;
}
.collection-packaging-right .text-box p{
    color: #555555;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 9px;
}
.collection-packaging-right .text-box ul li{
    color: #555555;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 11px;
}

.collectionSpecs ul li{
    color: #555555;
    font-size: 14px;
    font-weight: 400;
    margin-left: 12px;
    margin-bottom: 11px;
    position: relative;
}

.collectionSpecs ol li{
    color: #555555;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 11px;
    list-style: decimal;
    list-style-position: inside;
}

.collectionSpecs ul li::before {
    content: "• ";
    position: absolute;
    left: -0.8em;
    font-size: 1.1em;
}

.collection-bottom-desc ul li {
    list-style: disc;
    list-style-position: inside;
}
.collection-bottom-desc ol li {
    list-style: decimal;
    list-style-position: inside;
}
/*--------------------------------------
COLONIAL GRADES CSS
-------------------------------------*/
.colonial-grades-sec{
    background-color: #f4f4f4;
    padding: 100px 0;
}
.colonial-grades-sec .image-holder img{
    width: 100%;
}
.colonial-grades-sec .text-box {
    background-color: #f4f4f4;
    padding: 60px 63px 60px;
    margin-left: -63px;
    margin-top: 48px;
}
.colonial-grades-sec .text-box h3{
    color: #850037;
    line-height: 46px;
    font-family: 'FaunaOne';
    font-size: 40px;
    padding-bottom: 34px;
    position: relative;
}
.colonial-grades-sec .text-box span{
    font-weight: 500;
    display: block;
    color: #850037;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 27px 0 9px;
}
.colonial-grades-sec .text-box h3:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    background-color: #cacaca;
    height: 1px;
    width: 255px;
}
.colonial-grades-box-second {
    margin-top: 155px;
}
.colonial-grades-box-second .text-box{
    margin-left: auto;
    margin-right: -63px;
    text-align: right;
}
.colonial-grades-box-second .text-box h3:after{
    left: auto;
    right: 0;
}
.slideshow-sec .text-box{
    text-align: center;
    padding-bottom: 39px;
    padding-top: 94px;
}
.slideshow-sec .text-box span{
    display: inline-block;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid #cacaca;
}
.slideshow-sec .text-box p{
    max-width: 910px;
    font-weight: 400;
    margin: 50px auto 0;
}

.owl-carousel .owl-item img {
    min-height: 550px;
    max-height: 550px;
    object-fit: cover;
}
/*--------------------------------------
GALLERY CSS
-------------------------------------*/
.gallery-inner-sec{
    padding: 70px 0 120px;
    background-color: #fafafa;
}
.gallery-inner-sec .collection-breadcrumb {
    margin-bottom: 0px;
}
.gallery-inner-sec h2 {
    color: #292929;
    font-weight: 800;
    font-family: 'Montserrat';
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-left: -4px;
}

.gallery-inner-sec .text-block p {
    width: 70%;
    margin-bottom: 30px;
}

.gallery-inner-sec .mobile-version{
    display: none;
}
.gallery-inner-box .image-holder{
    background-color: #fff;
    border: 1px solid #cacaca;
    padding: 20px;
    margin-bottom: 30px;
    min-width: 350px;
    min-height: 350px;
    max-height: 350px;
}

.gallery-inner-box img {
    width: 100%;
}

.gallery-inner-box .left-img{
    background-color: #fff;
    border: 1px solid #cacaca;
    padding: 20px;
    margin-bottom: 30px;
    min-width: 730px;
    min-height: 730px;
    max-height: 730px;
}
.gallery-inner-box .right-img{
    background-color: #fff;
    border: 1px solid #cacaca;
    padding: 20px;
    margin-bottom: 30px;
    min-width: 730px;
    min-height: 730px;
    max-height: 730px;
}
.gallery-inner-box .image-holder img{
    object-fit: cover;
    height: 309px;
}
.gallery-inner-box .left-img img{
    object-fit: cover;
    height: 685px;
}
.gallery-inner-box .right-img img{
    object-fit: cover;
    height: 685px;
}

/* LIGHTBOX CSS */
.ril__navButtons {
    bottom: unset !important;
    top: 50px !important;
}

.ril__navButtonPrev {
    z-index: 999;
    width: 400px !important;
    background: rgba(255, 255, 255, 0)  url('../assets/images/dark-prev-arrow.svg') no-repeat center !important;
}

.ril__navButtonNext {
    z-index: 999;
    width: 400px !important;
    background: rgba(255, 255, 255, 0)  url('../assets/images/next-arrow.svg') no-repeat center !important;
}

.ril-image-next.ril__imageNext.ril__image {
    width: 1px;
}

.ril-image-prev.ril__imagePrev.ril__image {
    width: 1px;
}

/*
/.ril-image-current.ril__image {
/    position: absolute !important;
/    bottom: 40vh !important;
/}
*/


.ril__zoomInButton {
    display: none;
}

.ril__zoomOutButton {
    display: none;
}

.ril__outer {
    background-color: rgb(255, 255, 255) !important;
    height: 100vh;
}

.ril-caption-content.ril__captionContent {
    color: black;
}

.ril-toolbar.ril__toolbar {
    height: 150px !important;
    z-index: 998 !important;
    background-color: rgb(255, 255, 255) !important;
}

.ril__caption, .ril__toolbar {
    position: static;
    justify-content: center !important;
    background-color: rgba(255, 255, 255, 0) !important;
}

.ril-toolbar-left.ril__toolbarSide.ril__toolbarLeftSide {
    padding: 0
}

.ril-toolbar-right.ril__toolbarSide.ril__toolbarRightSide {
    padding: 0
}

.ril__closeButton {
    margin-top: 76px;
    filter: brightness(0%);
    background: url('../assets/images/lightbox-close.svg') no-repeat center !important;
}
.ril-caption.ril__caption {
    overflow: visible;
    scrollbar-width: none !important;
    position: fixed;
    bottom: 3%
}

.ril-caption.ril__caption::-webkit-scrollbar {
    display: none;
  }

.lightbox-caption {
    width: 600px;
    height: auto;
    min-height: 144.5px;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    text-align: center;
    background-color: white;
    padding: 25px;
}

.caption-title {
    font-family: 'Arsenal', sans-serif;
    font-size: 3em;
    margin-bottom: 30px;
}

.caption-desc {
    margin-top: -10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 50px;
}

.banner-sub-heading {
    margin-top:10px;
}

.banner-sub-heading p {
    color: #eee;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.2em;
    line-height: 20px;
    text-transform: uppercase;
}
/*--------------------------------------
DISTRIBUTION PAGE CSS
-------------------------------------*/
.distribution-banner{
    background: url(../assets/images/map-bg.png) no-repeat;
    background-size: cover;
    position: relative;
    padding: 155px 0 83px
}
.distribution-banner:after{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background-color: rgba(0, 97, 73, 0.8);
    width: 100%;
    height: 100%;
}
.distribution-banner .text-box {
    position: relative;
    z-index: 1;
}
.distribution-banner .text-box h2{
    color: #eee;
    font-family: "Montserrat",serif;
    font-weight: 800;
    font-size: 72px;
    letter-spacing: 0;
    text-transform: uppercase;
    word-break: normal;
    line-height: 1.0em;
}
.distribution-banner .text-box h2 span{
    font-size: 26px;
    display: inline-block;
    position: absolute;
    top: -20px;
}
.distribution-banner .text-box form{
    display: flex;
    max-width: 740px;
    width: 100%;
}
.distribution-banner .text-box form .form-control{
    border-radius: 0;
    border-left: 0;
    border-bottom: 1px solid #bababa;
    border-top: 0;
    color: #fafafa;
    font-size: 16px;
    font-style: italic;
    border-right: 0;
    padding-left: 30px;
    padding-bottom: 0;
    background-color: transparent;
    width: calc(100% - 261px);
    margin-right: 30px;
    height: 53px;
}
.distribution-banner .text-box form .form-control::placeholder{
    color: #fafafa;
}
.distribution-banner .text-box form a{
    color: #001f15;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: #eeeeee;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 261px;
    height: 54px;
    width: 100%;
}
.distribution-banner .text-box form a:hover{
    background-color: #850037;
    color: #fff;
}
.distribution-page .search-result-box .text-box{
    padding: 41px 0 50px 40px;
}
.distribution-page .search-result-box .text-box span{
    color: #252525;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 4px;
    display: block;
    margin-bottom: 8px;
}
.distribution-page .search-result-sec {
    padding: 134px 0 125px;
}
.distribution-page .search-result-box .text-box ul{
    margin: 29px 0 32px;
}
.distribution-page .search-result-box .text-box ul li{
    color: #252525;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 4px;
}
.distribution-page .search-result-box .text-box a{
    color: #00afd7;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
}
/*--------------------------------------
TEMPLATE PAGE CSS
-------------------------------------*/
.template-page .distribution-banner {
    /* Moved to Heritage page component
    background: url(../assets/images/template-banner-img.png) no-repeat;
    background-size: cover;
    */
    position: relative;
    min-height: 376px;
    max-height: 376px;
    display: flex;
    align-items: center;
}
.template-page .distribution-banner:after{
    display: none;
}
.text-box.dasktop-version ul li{
    list-style-type: disc;
}
.text-box.dasktop-version ol li {
    list-style-type: decimal;
}
.text-box.mobile-version ul li {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 40px;
}
.text-box.mobile-version ol li {
    list-style-type: decimal;
    list-style-position: inside;
    padding-left: 40px;
}

.tempate-qualtiy{
    background-color: #fafafa;
    padding: 102px 0 50px;
}

.tempate-qualtiy ol li{
    padding-left: 40px;
    list-style-type: decimal;
    list-style-position: inside;
}
.tempate-qualtiy .text-box h3{
    color: #555;
    font-size: 52px;
    font-weight: 300;
    line-height: 46px;
    font-family: "Arsenal",sans-serif;
    padding-bottom: 40px;
    position: relative;
    /*margin-bottom: 46px;*/
}
.tempate-qualtiy .text-box h3:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    background-color: #cacaca;
    height: 1px;
    width: 255px;
}

.tempate-qualtiy .text-box.text-center h3:after {
    position: absolute;
    content: "";
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-color: #cacaca;
    height: 1px;
    width: 255px;
}
/*--------------------------------------
TEMPLATE LETTER SEC CSS
-------------------------------------*/
/* Moved to Heritage page components
.template-letter-sec{
    background: url(../assets/images/letter-img.png) no-repeat;
    background-size: cover;

}*/
.template-letter-sec .text-box{
    background-color: #fafafa;
    text-align: center;
    padding: 95px 20px 75px;
    margin-top: 131px;
}
.template-letter-sec .text-box ul li{
    list-style-type: disc;
    list-style-position: inside;
}
.template-letter-sec .text-box ol li{
    list-style-type: decimal;
    list-style-position: inside;
}
.template-letter-sec .text-box span{
    display: inline-block;
    border-bottom: 1px solid #cacaca;
    color: #252525;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 0 50px 36px;
}
.template-letter-sec .text-box p{
    color: #252525;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    max-width: 920px;
    width: 100%;
    margin: 52px auto 35px;
    padding: 0 40px;
    letter-spacing: 0 !important;
}
.template-letter-sec .text-box h3{
    color: #252525;
    font-weight: 500;
    font-style: italic;
    font-size: 18px;
}
.template-letter-sec .text-box strong{
    margin-top: -10px;
    color: #777777;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
}
/*--------------------------------------
TEMPLATE MILLIONS SEC CSS
-------------------------------------*/
.template-page .distribution-banner .text-box.mobile-version{
    display: none;
}
.template-millions-sec {
    padding: 143px 0 110px;
    background-color: #eeeeee;
}
.template-title h3{
    color: #555;
    font-size: 40px;
    font-family: 'Arsenal';
    text-align: center;
    position: relative;
    margin-bottom: 25px;
}
.template-title h3:after{
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    width: 100%;
    height: 1px;
    background-color: #cacaca;
}
.template-title h3 span{
    background-color: #eeeeee;
    display: inline-block;
    padding: 0 30px;
    position: relative;
    z-index: 1;
}
.template-title .text-block h3 {
    color: #555;
    font-size: 32px;
    font-family: 'Arsenal';
    text-align: unset;
    position: relative;
    margin-bottom: 0px;
}
.template-title .text-block h3:after {
    display: none;
}
.template-title ul li {
    padding-left: 0;
    list-style-type: none;
    list-style-position: inside;
}
.template-title ol li {
    padding-left: 40px;
    list-style-type: decimal;
    list-style-position: inside;
}
.template-millions-inner.mobile-version{
    display: none;
}
.template-millions-inner{
    padding: 60px 0 0;
}
.template-millions-inner ul{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.template-millions-inner ul li{
    width: 20%;
    text-align: center;
    padding: 40px 0;
    margin: 0 50px 0 50px;
}
/*--------------------------------------
CONTACT US PAGE CSS
-------------------------------------*/
.contact-banner{
    background-size: cover;
    background-position: px;
    min-height: 376px;
    max-height: 376px;
    display: flex;
    align-items: center;
}

.table td, .table th {
    border-top: none;
}

.table td:not(:last-of-type) {
    padding-right: 100px;
}

.table p {
    line-height: 10px;
}

.contact-banner .text-box h2{
    color: #eee;
    font-family: "Montserrat",serif;
    font-weight: 800;
    font-size: 72px;
    letter-spacing: 0;
    text-transform: uppercase;
    margin-top: -50px;
    word-break: normal;
    line-height: 1.0em;
}
.contact-banner .text-box ul li{
    padding-left: 40px;
    list-style: disc;
    list-style-position: inside;
}
.contact-banner .text-box ol li{
    padding-left: 40px;
    list-style: decimal;
    list-style-position: inside;
}
.contact-us-page .search-result-sec {
    padding: 100px 0 70px;
    background-color: #f4f4f4;
}
.contact-us-page .search-result-detail{
    display: block;
    margin-bottom: 50px;
}
.contact-us-page .search-result-inner .title span {
    color: #252525;
    font-size: 12px;
    display: block;
    font-weight: 400;
    margin-bottom: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.contact-us-page .search-result-inner .title h3 {
    color: #555;
    font-size: 52px;
    font-weight: 300;
    line-height: 46px;
    font-family: "Arsenal",sans-serif;
    padding-bottom: 40px;
    position: relative;
}
.contact-us-page .search-result-inner .title h3:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    background-color: #cacaca;
    height: 1px;
    width: 255px;
}
.contact-us-page .search-result-inner .title {
    position: relative;
    padding-bottom: 32px;
    padding-left: 0px;
    margin-bottom: 45px;
}
.contact-us-page .search-result-detail .text-box{
    display: flex;
    max-width: 550px;
    width: 100%;
    justify-content: space-between;
    margin-top: 37px;
}
.contact-us-page .search-result-detail .text-box ul{
    display: block;
}
.contact-us-page .search-result-detail .text-box ul li{
    color: #252525;
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 13px 0;
}
.contact-us-page .search-result-box .text-box {
    border: 1px solid #cacaca;
    padding: 46px 28px 70px;
    margin-bottom: 30px;
    height: 100%;
    min-height: 224px;
    max-height: 224px;
}
.contact-us-page .search-result-box .text-box a{
    color: #00afd7;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.contact-us-page .search-result-box .text-box span{
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #252525;
    font-size: 12px;
    display: block;
    margin-bottom: 6px;
}
.contact-us-page .search-result-box .text-box h3{
    margin-bottom: 17px;
}
.contact-us-page .search-result-box .text-box p{
    letter-spacing: 3px;
}
#email-label {
    position: absolute;
    display: none;
    color: red;
    border: 1px solid red;
    padding-left: 30px;
    padding-right: 30px;
    bottom: 70px;
}
/*--------------------------------------
CONTACT US DETAIL SEC
-------------------------------------*/
.contact-us-detail{
    background-color: #fafafa;
    padding: 131px 0 85px;
}
.contact-us-detail-inner {
    max-width: 80%;
}
.contact-us-detail-inner .form-group {
    margin-bottom: 35px;
}
.contact-us-detail-inner .form-group .form-control{
    border-bottom: 1px solid #c3c3c3;
    color: #000000;
    border-left: none;
    border-top: none;
    border-right: none;
    border-radius: 0;
    background-color: transparent;
    padding-left: 30px;
    font-style: italic;
    height: 50px;
}
.form-control:focus{
    box-shadow: none;
}
.contact-us-detail-inner .form-group textarea.form-control{
    height: 145px;
    resize: none;
}
.contact-us-detail-inner .form-group .form-control::placeholder{
    color: #cacaca;
}
.contact-us-detail-inner .form-group select.form-control{
    background: url(../assets/images/select-down-arrow.png) right center no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.contact-us-detail-inner .form-group a{
    color: #fafafa;
    text-transform: uppercase;
    font-weight: 700;
    background-color: #209522;
    border-radius: 50px;
    font-size: 12px;
    letter-spacing: 2px;
    max-width: 261px;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    cursor: pointer;
}
.contact-us-detail-inner .form-group a:hover{
    background-color: #209522;
    color: #fff;
    opacity: 0.7;
}
.contact-us-getting{
    text-align: center;
    display: block;
}
.contact-us-getting .text-box{
    padding-bottom: 42px;
}
.contact-us-getting span{
    color: #252525;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 4px;
    border-bottom: 1px solid #555555;
    display: inline-block;
    padding: 0 23px 30px;
}
.contact-us-getting h3{
    color: #252525;
    font-size: 58.03px;
    font-family: 'Roboto Slab', serif;
    font-weight: 300;
    line-height: 64px;
    padding: 40px 0 34px;
}
.contact-us-getting p{
    color: #252525;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}
.mobile-manu{
    background-color: #021f16;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 57px 66px;
    display: none;
}
.mobile-manu .cross-icon{
    margin-bottom: 57px;
    display: inline-block;
}
.mobile-manu ul li{
    margin-bottom: 60px;
}
.mobile-manu ul li:last-child{
    margin-bottom: 0;
}
.mobile-manu ul li a{
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 4px;
}
.mobile-manu.active{
    display: block;
}

.search-filter{
    position: fixed;
    left: 0;
    top: 0;
    background-color: #f9fffd;
    width: 100%;
    padding: 57px 63px;
    height: 100%;
    z-index: 10;
    display: none;
    overflow-y: auto;
}
.search-filter.active{
    display: block;
}
.search-filter-box {
    margin-bottom: 65px;
}
.search-filter-box h3{
    color: #000000;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding-left: 20px;
    padding-bottom: 27px;
}
.search-filter-box ul li:last-child a{
    border-bottom: 1px solid #cacaca;
}
.search-filter-box ul li a{
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #cacaca;
    padding: 25px 20px;
}
.search-filter .cross-icon {
    padding: 0 0 57px 20px;
    display: inline-block;
}
.search-filter .cross-icon img{
    filter: brightness(-19.5%);
}

/*--------------------------------------
PRO-SHOP CSS
-------------------------------------*/
.pro-shop-container .product-category-featured {
    margin-bottom: 10px;
}

.pro-shop-container .product-category-featured-description {
    margin-bottom: 20px;
    line-height: 1.8em;
}

.pro-shop-container h2 {
    margin-left: -4px;
    margin-bottom: 10px;
    font-weight: 800;
}

.pro-shop-container .pro-shop-products-block {
    margin-top: 75px;
}

.pro-shop-container .product-category-featured {
    text-transform: unset;
}

.product-category-image img {
    display: block;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    max-height: 255px;
    max-width: 255px;
    min-height: 255px;
    min-width: 255px;
    object-fit: cover;
}

/*--------------------------------------
CART CSS
-------------------------------------*/

.cart-container {
    padding: 0 80px 0 80px
}

.cartHeaderText {
    color: #707070;
    font-weight: 500;
}

.cart-item-summary {
    margin-top: 50px;
}

.product-brand {
    margin-top: 25px;
    font-size: 11px;
    color: #555
}

.cart-product-holder {
    position: relative;
    border-bottom: 1px solid rgba(0,0,0,.1)
}

#table-product-description {
    vertical-align: top;
}

.cart-image-holder {
    height: 150px;
    width: 100px;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
}

.cart-image-holder img {
    border-radius: 8px;
    max-height: 140px;
    min-height: 140px;
    width: 100%;
    max-width: 100px;
    object-fit: cover;
}

.product-table-details {
    height: 150px;
}

.cart-product-action {
    font-size: 12px;
    width: 50px;
    position: absolute;
    bottom: 10px;
    text-decoration: underline;
    color: #536c66;
    cursor: pointer;
}

.cart-summary {
    border: 1px solid rgba(0,0,0,.1);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 40px;
    max-width: 300px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    height: 360px;
}

.cart-summary h3 {
    margin-bottom: 25px;
}

.cart-summary div {
    display: flex;
    font-size: 16px;
    font-weight: 500;
}

.cart-summary div:not(:last-of-type) {
    color: #888;
}

.cart-summary span {
    margin-left: auto;
}

.cart-summary p {
    margin-bottom: 10px;
}

.cart-summary button, .cart-summary label {
    margin: 25px auto 0 auto;
    justify-content: center;
    min-width: 100% !important;
}

.asst-number {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #857768;
    white-space: nowrap;
}

.recommended-container {
    margin-top: 100px;
}

.recommended-container h2 {
    font-family: 'Arsenal', sans-serif;
    padding-left: 20px;
    font-weight: 200;
}

.recommended-container .top-selling-product-title {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.recommended-container .top-selling-product-price {
    font-size: 16px;
    font-weight: 600;
}

.recommended-row {
    margin-top: 20px;
}

.recommended-row .top-selling-block {
    border: none;
    max-width: 195px;
    position: relative;
}

.recommended-row .top-selling-block:nth-child(n+1):after {
    content: '';
    height: 40%;
    width: 1px;
    position: absolute;
    right: 0;
    top: 15%;
    background-color: #e5e5e5;
}

.recommended-row .top-selling-block:nth-child(6):after {
    width: 0px;
}

.recommended-row .top-selling-block:nth-child(n+5) {
    margin-top: 0px;
}

.recommended-row .top-selling-block:nth-child(n+5) {
    margin-top: 0px;
}

/* CART SHIPPING & BILLING */
.shipping-form {
    margin-bottom: 50px
}

.shipping-form h3 {
    margin-bottom: 35px;
    color: #706458
}

.billing-form #same-as-shipping-address {
    margin-left: auto;
}


.billing-form #same-as-shipping-address input[type=checkbox] {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    outline: none;
    content: none;
    margin-left: 5px;
}

.billing-form #same-as-shipping-address input[type=checkbox]:before {
    position: relative;
    top: -2px;
    font-family: "FontAwesome";
    content: "\f00c";
    font-size: 10px;
    color: transparent !important;
    background: #fff;
    width: 25px;
    height: 25px;
    border: 1px solid #252525;
    margin-right: 3px;
}

.billing-form #same-as-shipping-address input[type=checkbox]:checked:before {
    color: white !important;
    background-color: #fca311 !important;
    border: 1px solid #fca311;
}

.billing-form h3 {
    margin-bottom: 35px;
    color: #706458
}

.addressee-heading {
    display: flex;
    align-items: center;
    font-weight: 500;
}

.addressee-heading h2 {
    color: #706458;
    font-weight: 400;
}

.addressee-heading a {
    margin-left: auto;
    text-decoration: underline;
    cursor: pointer;
    color: #536c66;
}

.shipping-overview {
    margin-top: 50px;
    border: 1px solid rgba(0,0,0,.1);
    padding: 50px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.shipping-overview h3 {
    font-size: 24px;
    font-weight: 400;
    color: #536c66;
    margin-bottom: 15px;
}

.shipping-overview p {
    font-size: 14px;
    font-weight: 400;
    color: #626262;
    margin-bottom: 5px;
}

.shipping-options {
    margin-top: 50px;
    border-top: 2px solid rgba(0,0,0,.1);
}

.shipping-method {
    padding: 25px 50px;
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.shipping-method input {
    position: relative;
    margin-right: 15px;
    top: 1px;
}

.shipping-method input:checked {
    accent-color: #fca311
}

.shipping-method p {
    margin-top: -10px;
    margin-left: 28px;
    color: #626262;
}

.shipping-method input[type=radio] {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    outline: none;
    content: none;
    margin-left: 5px;
}

.shipping-method input[type=radio]:before {
    position: relative;
    top: -2px;
    font-family: "FontAwesome";
    content: "\f00c";
    font-size: 10px;
    color: transparent !important;
    background: #fff;
    width: 25px;
    height: 25px;
    border: 1px solid #252525;
    margin-right: -3px;
}

.shipping-method input[type=radio]:checked:before {
    color: white !important;
    background-color: #fca311 !important;
    border: 1px solid #fca311;
}

.payment-options {
    position: relative;
    float: left;
    margin-top: 40px;
    max-width: 770px;
    width: 100%;
    height: 340px;
    background-size: contain;
    border-radius: 15px;
}

.payment-options label {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #6a6a6a;
}

.payment-options input {
    border: none;
    border-radius: 4px;
}

.card-front {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 490px;
    height: 100%;
    padding: 25px;
    background-color: #F0F0F0;
    border: 1px solid #F0F0F0;
    border-radius: 15px;
    box-shadow: 3px 5px 12px -4px #777777;
}

.expire-date {
    margin-top: 20px;
}

.expire-inputs {
    display: flex;
}

.expire-inputs select:first-of-type {
    margin-right: 10px;
}

.expire-inputs select {
    width: 145px;
    height: 50px;
    padding: 20px;
    border: none;
    border-radius: 4px !important;
    background-color: rgb(255, 255, 255) !important;
}

.card-back {
    position: absolute;
    z-index: 4;
    top: 0;
    right: 90px;
    width: 80%;
    height: 100%;
    background-color: #DEDEDE;
    border-radius: 15px;
 }

.card-cvv {
    float: right;
    width: 130px;
    margin: 8px 35px 0 0;
}

.card-cvv input {
    height: 50px;
    padding: 20px;
    width: 125px;
}

.card-cvv span {
    display: block;
    margin-top: 8px;
    font-size: 11px;
    font-weight: 500;
    font-style: italic;
    line-height: 12px;
    color: #6a6a6a;
}

.card-strip {
    float: left;
    clear: both;
    width: 100%;
    height: 65px;
    margin: 35px 0 20px;
    background-color: #111111;
}

.card-logos {
    float: right;
    margin-left: 10px;
}

.card-input {
    display: flex;
    flex-direction: column;
    max-width: 300px;
}

.card-name-input {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin-top: 20px;
}

#card-number-element {
    height: 50px;
    background-color: #ffffff;
    padding: 20px;
}

#expire-month {
    background-color: #ffffff !important;
    height: 50px;
    width: 125px;
    margin-right: 50px;
}

#expire-year {
    background-color: #ffffff !important;
    height: 50px;
    width: 125px;
}

#card-name-element {
    height: 50px;
    padding: 20px;
    background-color: #ffffff !important;
}

#cvv {
    background-color: #ffffff;
    height: 50px;
}

form input, select:not(#single-product) {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    padding: 0.375rem 1.5rem !important;
}

#single-product {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.6rem center;
    background-size: 1em;
}

.cart-product-holder #single-product {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 14px;
    height: 45px;
    appearance: none;
    width: 60px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.6rem center;
    background-size: 1em;
}

.search-no-results {
    text-align: center;
}

.search-no-results h1 {
    font-size: 42px;
    font-weight: 800;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.search-no-results p {
    font-size: 16px;
    line-height: 28px;
    margin-top: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.search-page-product-title {
    flex: 0;
    font-size: 16px;
    margin-top: 3px;
}

.search-product-category {
    flex: 1 1;
    font-size: 12px;
    color: #292929;
    margin-top: 0px;
}

.search-product-option {
    font-size: 12px;
    color: #292929;
    margin-top: 4px;
}

.search-cart-button-container {
    text-align: left;
}

.search-cart-button {
    height: 25px;
    min-width: 100px;
    font-size: 12px;
}

.pagination {
    justify-content: center;
    margin-top: 80px;
}

.pagination li a {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 20px;
    margin: 20px 15px;
    text-align: center;
    background-color: #eeeeee;
    color: black;
    line-height: 10px;
    white-space: nowrap;
    border: none;
}

.pagination li a {
    margin-left: -2px;
}

.page-item.active span {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 20px;
    margin: 20px 15px 20px 0px;
    text-align: center;
    border: 1px solid black;
}

.page-item.active .page-link {
    color: #62a6ff;
    background-color: transparent;
    line-height: 10px;
}

.next-pagination {
    margin-left: auto;
}

.previous-pagination {
    margin-right: auto;
}

/* Helper Classes */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}
